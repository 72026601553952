<div
  class="w-full bg-gray-800  shadow-2xl rounded-lg p-5 md:p-10 flex flex-col md:flex-row justify-between items-center">


  <!-- PROJECT IMAGE   -->
  <div class="flex w-full md:w-1/3 md:flex-1 justify-start items-center ">
    <img class=" rounded-lg shadow-2xl" [src]='"assets/images/" + project.projectImg' alt="Project Image">
  </div>

  <!-- PROJECT DESC WRAPPER -->
  <div class="w-full md:w-2/3 flex md:flex-1 flex-col py-3 md:py-0 space-y-5 justify-between">

    <!-- PROJECT NAME -->
    <span class="flex w-full justify-end items-center font-semibold text-gray-200 text-4xl text-right">
      {{project.projectName}}
    </span>

    <div class="flex w-full justify-end items-center">
      <app-chips [name]="project.isOpenSource ? 'Open-Source' : 'Side-Project'"
        [type]="project.isOpenSource ? 'PINK300' : 'GREEN300'"></app-chips>
    </div>

    <!-- PROJECT DESCRIPTION  -->
    <span class="flex w-fulljustify-end items-center text-gray-500 text-2xl text-right">
      {{project.projectDescription}}
    </span>

    <!-- BUTTON SECTION -->
    <div class="flex  w-full justify-end space-x-3 items-center">

      <!-- PROJECT LIVE LINK -->
      <app-custom-button *ngIf="project.isProjectLinkPresent" (onBtnClicked)="viewProject($event)" size="MD"
        bgColor="INDIGO600" btnLabel="View" [isIconActive]="true" iconType="VIEW" iconSize="LG">
      </app-custom-button>

      <!-- PROJECT GITHUB LINK -->
      <app-custom-button *ngIf="project.isProjectGithubLinkPresent" (onBtnClicked)="viewGithubProject($event)" size="MD"
        bgColor="GRAY900" btnLabel="Github" [isIconActive]="true" iconType="GITHUB" iconSize="LG">
      </app-custom-button>

    </div>
  </div>
</div>
