  <!-- ROW_START -->
  <div class="w-full h-full flex py-10">

    <!-- LEFT_SECTION -->
    <div
      class="w-full md:w-1/2 h-full px-5 md:px-16 space-y-2 md:space-y-10 flex flex-col justify-center  items-center ">

      <div class="flex flex-col justify-center items-center md:items-start w-full">
        <app-sub-heading @slideInFromRightAndFade  subHeadingText="Who am I"></app-sub-heading>
        <app-heading @slideInFromRightAndFade  bgColor="DEFAULT_GRADIENT" headingText="About Me"></app-heading>
      </div>

      <div
        class="flex w-full text-gray-400 md:pr-20 font-base text-lg md:text-xl justify-center md:justify-start items-center">
        <span @slideInFromRightAndFade  class="text-center md:text-left">Hey, I'm Harsard. I am  working as a Senior Software Engineer  having total of more than 5 years of experience under my belt. I am
          based out of Kandy, Sri Lanka
          <br>
          I am experienced in building Java and Spring boot based webapps. I am comfortable working with web technologies
          like Angular, Javascript .</span>
      </div>

      <div class="flex w-full justify-center md:justify-start  items-center">
        <app-custom-button @slideInFromRightAndFade  (onBtnClicked)="openResume($event)" size="LG" bgColor="INDIGO600" btnLabel="Download Resume"
          [isIconActive]="true" iconType="DOWNLOAD" iconSize="MD" >
        </app-custom-button>
      </div>
    </div>

    <!-- RIGHT_SECTION -->
    <div class="hidden md:w-1/2 md:h-full justify-end md:flex">
      <img @fade hidden class="hover:scale-105 " src="../../../assets/images/about.jpg" alt="">
    </div>
  </div>
