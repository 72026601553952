<div class="w-full p-5 md:pt-16 md:pb-5 md:px-16 flex justify-between items-center">
  <a routerLink="" class="text-white font-bold text-xl">
    Harsard M
  </a>
  <div class="group hidden md:flex md:space-x-12">
    <a routerLink="" class="text-gray-400 font-semibold text-lg hover:text-white">
      Home
    </a>

    <a routerLink="/about" class="text-gray-400 font-semibold text-lg hover:text-white">
      About
    </a>

    <a routerLink="/projects" class="text-gray-400 font-semibold text-lg hover:text-white">
      Projects
    </a>

    <a href="https://harshbharvada.hashnode.dev/" target="_blank"
      class="text-gray-400 font-semibold text-lg hover:text-white">
      Blog
    </a>

    <a href="javascript:void(0)" (click)="navigateTo('Contact')"
      class="text-gray-400 font-semibold text-lg hover:text-white">
      Contact
    </a>
  </div>
</div>


<div class="md:hidden xl:hidden fixed z-50 top-auto bottom-0 w-full p-4  ">
  <nav class="flex justify-evenly py-4 bg-indigo-600 shadow-2xl rounded-lg overflow-hidden">
    <a href="javascript:void(0)" (click)="navigateTo('Home')" [ngClass]="{'text-indigo-900': active === 'Home'}"
      class="flex flex-col space-y-1 text-gray-300  justify-center items-center cursor-pointer text-sm font-semibold ">
      <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path
          d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
      </svg>
      Home
    </a>

    <a href="javascript:void(0)" (click)="navigateTo('About')" [ngClass]="{'text-indigo-900': active === 'About'}"
      class="flex flex-col space-y-1 text-gray-300 justify-center items-center cursor-pointer text-sm font-semibold ">
      <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd"
          d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
          clip-rule="evenodd" />
        <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
      </svg>
      About
    </a>

    <a href="javascript:void(0)" (click)="navigateTo('Projects')" [ngClass]="{'text-indigo-900': active === 'Projects'}"
      class="flex flex-col space-y-1 text-gray-300 justify-center items-center cursor-pointer text-sm font-semibold">
      <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path
          d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
      </svg>
      Projects
    </a>

    <a href="javascript:void(0)" (click)="navigateTo('Home')" [ngClass]="{'text-indigo-900': active === 'Blog'}"
      class="flex flex-col space-y-1 text-gray-300 justify-center items-center cursor-pointer text-sm font-semibold ">
      <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd"
          d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
          clip-rule="evenodd" />
      </svg>
      Blog
    </a>

    <a href="javascript:void(0)" (click)="navigateTo('Contact')" [ngClass]="{'text-indigo-900': active === 'Blog'}"
      class="flex flex-col space-y-1 text-gray-300 justify-center items-center cursor-pointer text-sm font-semibold ">
      <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path
          d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
      </svg>
      Contact
    </a>

  </nav>
</div>

<router-outlet></router-outlet>
