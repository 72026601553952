<div class="w-full h-full md:w-screen md:h-screen bg-gray-900 flex flex-col p-3 space-y-10 md:p-16">

    <div class="w-full flex flex-col space-y-1 justify-center items-start">
      <app-sub-heading subHeadingText="What others have to say"></app-sub-heading>
      <app-heading bgColor="DEFAULT_GRADIENT" headingText="Recommendations"></app-heading>
    </div>
  
    <div #recommSec  [@staggerFade]="inView ? 'open' : 'close'" class="w-full px-3 h-full space-y-5 md:space-y-0 md:space-x-10 flex flex-col md:flex-row justify-center items-center">
      <app-testimonial-card class="eachStaggerComponent  flex-1 h-full" *ngFor="let testimonial of testimonialDataList; trackBy:trackByFeedbackBy"
        [testimonialData]="testimonial"></app-testimonial-card>
    </div>
  </div>
  