<div id="contact" class="w-full h-full bg-gray-800 flex flex-col px-3 py-5 space-y-20 md:space-y-10 md:p-16">

  <div class="w-full flex flex-col space-y-1 justify-center items-start">
    <app-sub-heading subHeadingText="What’s on your mind"></app-sub-heading>
    <app-heading bgColor="DEFAULT_GRADIENT" headingText="Get In Touch"></app-heading>
  </div>

  <div class="w-full h-full space-y-4 flex flex-col justify-center items-center">

    <div class="w-full flex text-gray-400 text-xl font-medium">
      Excited to work together on something? I am always open to opportunities to learn something new everyday.
      Let’s connect!
    </div>

    <div class="bg-gray-900 text-gray-400 rounded-lg flex justify-between items-center">
        <div class="text-left w-full p-4">
            harsard@gmail.com
        </div>

        <div class="bg-indigo-600 w-full h-full rounded-lg p-5">
          <a href="mailto:harsard@gmail.com">
            <app-icon iconType="SEND" iconSize="MD"></app-icon>
          </a>
        </div>
    </div>

    <div class="w-full flex space-x-4 justify-center items-center p-4">
      <a href="https://github.com/harsard" target="_blank" >
        <app-icon class="text-white" iconType="GITHUB" iconSize="XL"></app-icon>
      </a>

      <a href="https://dribbble.com/harsard" target="_blank" >
        <app-icon class="text-white" iconType="DRIBBBLE" iconSize="XL"></app-icon>
      </a>

      <a href="https://www.linkedin.com/in/harsh-bharvada/" target="_blank" >
        <app-icon class="text-white" iconType="LINKEDIN" iconSize="XL"></app-icon>
      </a>

      <a href="https://twitter.com/HBharvada" target="_blank" >
        <app-icon class="text-white" iconType="TWITTER" iconSize="XL"></app-icon>
      </a>

      <a href="https://www.hackerrank.com/harsh07" target="_blank" >
        <app-icon class="text-white" iconType="HACKERRANK" iconSize="XL"></app-icon>
      </a>

      <a href="https://leetcode.com/harsard/" target="_blank" >
        <app-icon class="text-white" iconType="LEETCODE" iconSize="XL"></app-icon>
      </a>

    </div>
  </div>

  <div class="py-5 md:hidden">

  </div>
</div>
