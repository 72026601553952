export enum HeadingColorClasses {

    DEFAULT_GRADIENT = "bg__gradient__default",
    DEFAULT = "text-gray-100"
}

export enum HeadingColors {
    
    DEFAULT_GRADIENT = 'DEFAULT_GRADIENT',
    DEFAULT = 'DEFAULT'
}