<div class="w-full h-full flex flex-col space-y-8 p-5 md:px-16">

  <div class="w-full flex flex-col py-8 justify-center items-start">
    <app-sub-heading subHeadingText="What I have worked on"></app-sub-heading>
    <app-heading bgColor="DEFAULT_GRADIENT" headingText="Projects & Open Source Contributions"></app-heading>
  </div>

  <app-project-card [@fadeInView]="inViewList[i] ? 'open' : 'close'" class="w-full eachStaggerComponent" *ngFor=" let project of projects; let i = index;" [project]="project"></app-project-card>

</div>
