<div class="w-full h-full flex space-x-2 py-2">

    <div class="h-14 w-14 flex justify-center items-center p-4 bg-gray-800 text-indigo-600 rounded-md">
        <app-icon [iconType]="dataState.iconType" iconSize="LG"></app-icon>
    </div>

    <div class="w-full flex flex-col justify-between items-center">
        <span class=" w-full text-gray-600 font-bold text-xl md:text-3xl text-left">
            {{dataState.headingText}}
        </span>

        <span class="w-full text-gray-400 font-medium text-sm md:text-xl py-3 pr-10 text-left leading-relaxed">
            <span class="text-gray-500">{{dataState.subHeadingText}}</span>
            {{dataState.descriptionText}}
        </span>
    </div>
    
</div>