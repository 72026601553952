<div  class="w-full h-full md:w-screen md:h-screen bg-gray-900 flex flex-col p-3 space-y-10 md:p-16">

    <div class="w-full flex flex-col space-y-1 justify-center items-start">
        <app-sub-heading subHeadingText="How do I work"></app-sub-heading>
        <app-heading bgColor="DEFAULT_GRADIENT" headingText="Development Process"></app-heading>
    </div>

    <div #devProcess  [@staggerFade]="inView ? 'open' : 'close'" class="w-full h-full space-y-5 flex flex-col justify-center items-center">

        <!-- FIRST_ROW -->
        <div class="flex flex-col md:flex-row w-full justify-between items-start">
            <app-process-info type="RESEARCH" class="eachStaggerComponent w-full md:w-1/2"></app-process-info>
            <app-process-info type="DESIGN" class=" eachStaggerComponent w-full md:w-1/2"></app-process-info>
        </div>

        <!-- SECOND -->
        <div class="flex flex-col md:flex-row w-full justify-between items-start">
            <app-process-info  type="DEV" class=" eachStaggerComponent w-full md:w-1/2"></app-process-info>
            <app-process-info  type="QA" class=" eachStaggerComponent w-full md:w-1/2"></app-process-info>
        </div>
    </div>
</div>