<div class="bg-gray-700 h-full  rounded-2xl flex flex-col justify-between items-center">

    <div class="bg-gray-800 w-full h-full text-sm md:text-lg rounded-2xl shadow-2xl flex justify-center items-center p-10">
        <span class="text-gray-400  italic">
            {{testimonialData.feedback}}
        </span>
    </div>

    <div class="w-full flex justify-around items-center text-gray-300 py-5">
        <span class="font-semibold leading-relaxed">
            {{testimonialData.feedbackBy}}
        </span>

        <app-icon iconType="LINKEDIN" iconSize="MD"></app-icon>
    </div>
</div>